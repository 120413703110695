/* @import '~antd/dist/antd.css'; */

.testGroup{
  display: flex;
  background-color: hotpink;
}

.testMenu{
  /* background-color: khaki; */
  flex: 0.1;
  /* height: 400px; */
  /* display: inline-block; */
}
 /* .testLabel{
  display: inline-block;
  color: green;
}  */
.testFrame{
  /* display: inline-block; */
  height: calc(98vh);
  /* width: 50%; */
  flex: 1;
  
  /* margin-left: 3%; */
  /* background-color: khaki; */
}
