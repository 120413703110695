/* @import '~antd/dist/antd.css'; */


.labelGroup{
  font-size: 25px;
}

.nameGroup, .addressGroup, .picLabelGroup, .picImageGroup{
  margin-top: 30px;
  margin-left: 30px;
}
.nameGroup{
  margin-top: 70px;
}
.picImageGroup{
  margin-top: 10px;
}
.name, .address{
  
  margin-left: 40px;
}

.image{
  margin-right: 20px;
  /* margin-bottom: 10px; */
  width: 100px;
  /* height: 150px; */
}
.imageGroup{
  margin-left: 40px;
}





