/* @import '~antd/dist/antd.css'; */

.nameDiv{
  margin-top: 50px;
  margin-left: 100px;
}

.nameItem{
  width: 70%;
}

.addressDiv{
  margin-left: 100px;
}
.addressItem{
  
  width: 70%;
}
.submit{
  margin-left:-50px;
  margin-top: 20px;
  
}
.reset{
  margin-left:50px;
}

