/* @import '~antd/dist/antd.css'; */

/* .testDiv{
  display: flex;
} */

.testTable{
  /* flex: 0.5; */
  margin-left: 50px;
  width: 600px;
  background-color: white;
}


