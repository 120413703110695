/* @import '~antd/dist/antd.css'; */

.nameDiv{
  margin-top: 50px;
  margin-left: 100px;
}
.nameItem{
  width: 70%;
}

.telDiv{
  margin-left: 100px;
}
.telItem{
  width: 70%;
}

.remainderDiv{
  margin-left: 100px;
}
.remainderItem{
  width: 70%;
}

.submit{
  margin-left:-50px;
  margin-top: 20px;
}
.reset{
  margin-left:50px;
}

