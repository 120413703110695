/* @import '~antd/dist/antd.css'; */

.toolbar{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}
.username{
  margin-right: 10px;
}
.exit{
  margin-right: 10px;
}

.testGroup{
  display: flex;
  background-color: white;
  
}

.testMenu{
  flex: 0.1;
  /* border-left: 1px solid #aaa; */
  border-top: 1px solid #ddd;
}

.testFrame{
  height: calc(98vh);
  flex: 1;
  border: 1px solid #ddd;
}
